
import 'typed.js'
import 'mmenu-light'
import 'mmenu-light/dist/mmenu-light.css'


// import styles bundle
import 'swiper/css/bundle';
import '../scss/styles.scss'

const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if( entry.isIntersecting ){
            entry.target.classList.add('showEl');
        }
    })
})

const hiddenElements = document.querySelectorAll('.hiddenEl');
hiddenElements.forEach((el) => observer.observe(el));

// SCROLL HEADER
window.addEventListener('DOMContentLoaded', () => {
    document.addEventListener('scroll', () => {
        if (window.scrollY > 100 ){
            document.querySelector('header').classList.add('small-header')
        }else{
            document.querySelector('header').classList.remove('small-header')
        }
    })
})




// HAMBURGER MENU
// const hamburger = document.getElementById('hamburger');
//
// if(hamburger){
//     hamburger.addEventListener('click', (e) =>{
//         document.body.classList.toggle('open');
//     });
// }



const triggers = document.getElementsByClassName('downloadItem modalItem');
const triggerArray = Array.from(triggers).entries();
const modals = document.getElementsByClassName('modal-background');
const closeButtons = document.getElementsByClassName('btn-close');

for (let [index, trigger] of triggerArray) {
    const toggleModal = () => {
        modals[index].classList.toggle('show-modal');
    }
    trigger.addEventListener("click", toggleModal);
    if(closeButtons){
        closeButtons[index].addEventListener("click", toggleModal);
    }
}
window.addEventListener('DOMContentLoaded', () => {
   document.body.addEventListener('click', (e) => {
       if(e.target === document.querySelector('.show-modal')) {
           document.querySelector('.show-modal').classList.remove('show-modal')
       }
   });
})




window.addEventListener('DOMContentLoaded', () => {
    const menu = new MmenuLight(
        document.querySelector( "#mobileNav" )
    );

    const navigator = menu.navigation({
        theme: 'dark',
        title: 'Menü'
    });
    const drawer = menu.offcanvas({
        position: 'left'
    });

    document.querySelector( "a[href='#mobileNav']" )
        .addEventListener( 'click', ( evnt ) => {
            evnt.preventDefault();
            drawer.open();
            document.querySelector("#mobileNav").style.display = "block"
            const submenus = document.querySelectorAll('.mm-ocd .sub-menu');
            submenus.forEach((submenu) => {
                submenu.classList.remove('sub-menu')
            })
        });
});



